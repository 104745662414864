import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Button from '../elements/Button';
import axios from 'axios';
import ReactGA from 'react-ga';
import ReCAPTCHA from "react-google-recaptcha";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}
const recaptchaRef = React.createRef();
function onChange(value) {
  console.log("Captcha value:", value);
}
class HeroFull extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      videoModalActive: false,
      demoModalActive: false,
      formname: "demo", 
      formemail: "demo"
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleSubmitDevForm = this.handleSubmitDevForm.bind(this);

    ReactGA.initialize('UA-71944613-7');

  }


  openModal = (e) => {
    ReactGA.event({
      category: 'get_started',
      action: 'opened',
      label: 'header'
    });
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  handleSubmitDevForm(event) {
    event.preventDefault();
    // const recaptchaValue = recaptchaRef.current.getValue();

    // if(recaptchaValue == ""){
    //   console.log("recaptchaValue is null");
    // } else {
    
      ReactGA.event({
        category: 'get_started',
        action: 'submitted',
        label: 'header'
      });
      var state2 = this;
      var name = this.state.formname;
      var email = this.state.formemail;
      this.closeModal(event);
      axios({
        method: 'post',
        url: 'https://23dbtikyf0.execute-api.eu-central-1.amazonaws.com/deployment/playtesting-games/dev-form',
        data: {
          name: name,
          email: email,
        }
      })
      .then(res => {
        console.log("response: " + res.data);
        state2.setState({ showSuccessMessage: true })
      })
      .catch(error => {
        console.log("ERROR!! " + error)
      })
    // }
    
  } 

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  handleChangeEmail(event) { this.setState({formemail: event.target.value}); }
  handleChangeName(event) { this.setState({formemail: event.target.value}); }
  

  render() {
    const {
      className,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

   

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className="hero-content">
              <div className="container-xs">
                <h1 
                  className="mt-0 reveal-from-left HeaderTitle"
                  >
                  Actionable Feedback with 
                  <span className="playtestingVideos">remote playtesting</span>
                </h1>
                <Button 
                  tag="a" 
                  onClick={this.openModal} 
                  color="primary" 
                  wideMobile
                  className="getStartedCTA"
                >
                  Get started
                </Button>
                <p className="ctaSubtitle">Get 2 free playtests for your game</p>
              </div>
            </div>
            <div className="hero-figure  reveal-scale-up">
          
                <video 
                  autoPlay 
                  loop 
                  muted 
                  className="HeaderVid"
                >
                  <source src="https://gamesinfo.s3.eu-central-1.amazonaws.com/final_5e32aa33900ec9001638cab1_395561.mp4" type="video/mp4"/>
                </video>
          
            </div>
            
            <Modal
        id="demo-modal"
        show={this.state.demoModalActive}
        handleClose={this.closeModal}
      >
        <div className="center-content">
          <h3 className="mt-0 mb-12">Claim your 2 free playtests!</h3>
          <p className="text-sm">
            We will get back to you within a day
          </p>
        </div>
        
        <form style={formStyle} onSubmit={this.handleSubmitDevForm}>
          <div className="mb-24">
            <Input
              type="email"
              onChange={this.handleChangeEmail}
              placeholder="Your work email..."
              formGroup="desktop"
              >
            </Input>
          </div>
          <div className="mb-24">
            <Input
              type="text"
              onChange={this.handleChangeName}
              placeholder="Your name..."
              formGroup="desktop"
              >
            </Input>
          </div>
          {/* <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LeHNtgUAAAAANyMviBN62A5JsnTE71fXl-XpVce"
                    onChange={onChange}
                  /> */}
          <Button 
            type="submit"
            className="button button-primary button-sm"
            // onClick={this.handleSubmitDevForm} 
            Color="primary">
              Claim free playtests
          </Button>
        </form>


       
          <div className="center-content mt-24">
            <a
              className="func-link text-xxs fw-500 tt-u"
              aria-label="close"
              href="#0"
              onClick={this.closeModal}
            >No thanks!</a>
          </div>
        </Modal>
          </div>
        </div>
      </section>
    );
  }
}


const formStyle = {
  maxWidth: '520px',
  margin: '0 auto'
}
HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;