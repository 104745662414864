import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import HeroFull from '../components/sections/HeroFull';
import RequestAccountDeletion from '../components/sections/RequestAccountDeletion';

import FAQ from '../components/sections/FAQ';
import ReactGA from 'react-ga';
import HowItWorks from '../components/sections/HowItWorks';

import { Redirect } from 'react-router-dom';
// import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

ReactGA.initialize('UA-71944613-7');

class DeleteAccount extends React.Component {

  render() {
    return (
      <React.Fragment>
        {/* <HeroSplit /> */}
        {/* <HeroSplit imageFill className="illustration-section-01" /> */}
        {/* <FeaturesSplit invertMobile imageFill className="illustration-section-05" /> */}
        <RequestAccountDeletion />

        {/* <Pricing pricingSwitcher className="illustration-section-03" /> */}
        {/* <HowItWorks className="" /> */}
        <FAQ />
        <Cta topDivider bottomDivider split />
      </React.Fragment>
    );
  }
}


export default DeleteAccount;