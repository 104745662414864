import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Lottie from "lottie-react";
import * as animationDatadeadPool from '../../assets/animations/deadpool.json'
import GenericSection from './GenericSection';
import Accordion from '../elements/Accordion';
import AccordionItem from '../elements/AccordionItem';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types
}

const defaultProps = {
  children: null,
  ...SectionProps.defaults
}

class FAQ extends React.Component {

  render() {
    const deadPoolOptions = {
      loop: true,
      autoplay: true,
      animationData: animationDatadeadPool.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };


    const genericSection04Header = {
      title: 'Your questions, answered'
    }


    return (
      <div className="row">

        <div className="col-12">
          <div className="container-xs">
            <Lottie options={deadPoolOptions}
              style={{
                width: `200px`,
                height: `300px`
              }}
            />
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
              <AccordionItem title="What is Octappush?" active>
                Octappush is SaaS Platform that connects mobile game testers with mobile game developers.
                Our clients are the game developers that pay us to have access to our community of testers
                and get our testers to test their game using our proprietary software
              </AccordionItem>
              <AccordionItem title="How Testers Are Chosen?">
                In order to receive a paid test, users should have
                <ul>
                  <li>Validated their email</li>
                  <li>Successfully completed and pass the qualification playtest</li>
                  <li>Have added games to their profile</li>
                </ul>

                When we get an order from our clients, they select the demographics and the gaming behavior
                that they want the testers to have. We match testers with tests based on those criteria given by our clients.
                Also if you had previously participated in paid playtest, there is an internal scoring system that reflects how valuable is the feedback that you give based on some factors.
                <br></br><br></br>
                That way we try to be as fair as we can so anyone have the chance to get selected as a tester.
              </AccordionItem>
              <AccordionItem title="Do I Have To Install Anything?">
                Once you have been selected for a test, we will let you know via email, the next steps that you should take.                   </AccordionItem>
              <AccordionItem title="How Am I Going To Get Paid?">

                We currently support payouts only via Paypal.



              </AccordionItem>
              <AccordionItem title="How Much Am I Going To Get Paid?">
                Depending on the type of test that you will execute you can earn up to $5 to $15 per test.
              </AccordionItem>
              <AccordionItem title="Why do I have to do the qualification test?">
                It's the safest and quickest way for you as a tester to find out if you phone can support our device and understand the testing process.
                It's also a way for us to validate that your response the qualification playtest is up to our standards. We aim to have quality testers.
              </AccordionItem>
              <AccordionItem title="Is this a full time or part time job?">
                <b>NO!</b>
                <br></br>
                Expect to receive 1-2 paid tests per month if you demographics and gaming behavior matches the criteria our clients set.
              </AccordionItem>
              <AccordionItem title="I am developer! Where can I learn more?">
                If you a developer please visit: <a target="_blank" href="https://playtesting.games/"> playtesting.games</a>
              </AccordionItem>
              <AccordionItem title="How Do I Sign Up for Octappush?">
                Signing up for Octappush is easy! Simply download the Octappush app from the Play Store, complete your gamer profile, and take the qualification test to get started.
              </AccordionItem>
              <AccordionItem title="What Kind of Feedback Should I Provide?">
                When testing games, it's important to provide feedback on gameplay, design, and any issues you encounter. Be detailed and constructive in your feedback to help game developers improve their games.
              </AccordionItem>
              <AccordionItem title="Can I Test Games on Any Device?">
                We support a wide range of Android devices, but it's essential to ensure your device is compatible by taking the qualification test. This helps us match you with suitable tests.
              </AccordionItem>
              <AccordionItem title="How Often Will I Receive Test Opportunities?">
                Test opportunities vary based on your demographics and gaming behavior. While we aim to provide 1-2 paid tests per month, it's not a full-time job, and availability can vary.
              </AccordionItem>
              <AccordionItem title="Is My Personal Data Safe?">
                We take your privacy seriously. Your personal data is not collected during testing. We only use the "Accessibility Permission" during testing to enhance your experience.
              </AccordionItem>
              <AccordionItem title="What If I Encounter Technical Issues?">
                If you encounter technical issues during testing, please reach out to our dedicated support team at support@octappush.com. We're here to assist you promptly.
              </AccordionItem>
              
              <AccordionItem title="Are There Any Age Restrictions?">
                To be a tester, you must meet the minimum age requirement for mobile game testing, which is typically 18 years old. Check our website for specific age requirements.
              </AccordionItem>
              <AccordionItem title="How Long Do Playtests Typically Last?">
                The duration of playtests can vary, but most tests can be completed in a reasonable amount of time. Expect to spend anywhere from 30 minutes to a few hours per test.
              </AccordionItem>
              <AccordionItem title="What Happens if I Don't Qualify for a Test?">
                If you don't qualify for a specific test, don't worry! Keep your profile updated, and you may be eligible for other opportunities in the future. Your participation is valuable.
              </AccordionItem>
              <AccordionItem title="How Do I Update My Gamer Profile?">
                To update your gamer profile, log in to the Octappush app and go to your profile settings. You can add or edit your favorite mobile games and other relevant information there.
              </AccordionItem>
              <AccordionItem title="Can I Participate in Multiple Tests Simultaneously?">
                Yes, you can participate in multiple tests, but it's important to complete each test thoroughly before starting another one.
              </AccordionItem>
              <AccordionItem title="What If I Can't Complete a Test?">
                If you encounter issues or cannot complete a test, please contact our support team immediately. We understand that technical problems can arise.
              </AccordionItem>
              <AccordionItem title="Are There Any Geographic Restrictions?">
                Octappush is available to users in various regions, but test availability may vary by location. We strive to offer opportunities worldwide.
              </AccordionItem>
              <AccordionItem title="How Are Payments Processed?">
                Payments are processed through PayPal. Ensure your PayPal account is linked to your Octappush profile to receive your earnings.
              </AccordionItem>
              <AccordionItem title="What Happens to My Test Feedback?">
                Your feedback is invaluable to game developers. It is used to improve games and enhance the gaming experience for players worldwide.
              </AccordionItem>
              <AccordionItem title="How Can I Improve My Chances of Getting Selected for Tests?">
                Keep your profile updated, participate in qualification playtests, and provide high-quality feedback to improve your chances of being selected for more tests.
              </AccordionItem>
              <AccordionItem title="Can I Test Games for Specific Platforms (e.g., iOS)?">
                Currently, Octappush focuses on mobile game testing for Android devices. Support for other platforms may be considered in the future.
              </AccordionItem>
              <AccordionItem title="Do I Need to Pay to Join Octappush?">
                No, joining Octappush as a game tester is completely free. There are no fees or hidden costs associated with becoming a tester.
              </AccordionItem>
              <AccordionItem title="How Do I Report Inappropriate Content or Behavior?">
                If you encounter inappropriate content or behavior during a test, please report it to our support team immediately. We take such matters seriously and will investigate promptly.
              </AccordionItem>

            </Accordion>
          </div>
        </div>
      </div>

    );
  }
}

FAQ.propTypes = propTypes;
FAQ.defaultProps = defaultProps;

export default FAQ;