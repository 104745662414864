import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Lottie from "lottie-react";
import * as animationDatadeadPool from '../../assets/animations/deadpool.json'
import GenericSection from './GenericSection';
import Accordion from '../elements/Accordion';
import AccordionItem from '../elements/AccordionItem';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types
}

const defaultProps = {
  children: null,
  ...SectionProps.defaults
}

class FAQPageComponent extends React.Component {

  render() {
    const deadPoolOptions = {
      loop: true,
      autoplay: true,
      animationData: animationDatadeadPool.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };


    const genericSection04Header = {
      title: 'Your questions, answered'
    }


    return (
      <div className="row">

        <div className="col-12">
          <div className="container-xs">
            <Lottie options={deadPoolOptions}
              style={{
                width: `200px`,
                height: `300px`
              }}
            />
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
              <AccordionItem title="What is Octappush?" active>
                Octappush is SaaS Platform that connects mobile game testers with mobile game developers.
                Our clients are the game developers that pay us to have access to our community of testers
                and get our testers to test their game using our proprietary software
              </AccordionItem>
              <AccordionItem title="How Testers Are Chosen?">
                In order to receive a paid test, users should have
                <ul>
                  <li>Validated their email</li>
                  <li>Successfully completed and pass the qualification playtest</li>
                  <li>Have added games to their profile</li>
                </ul>

                When we get an order from our clients, they select the demographics and the gaming behavior
                that they want the testers to have. We match testers with tests based on those criteria given by our clients.
                Also if you had previously participated in paid playtest, there is an internal scoring system that reflects how valuable is the feedback that you give based on some factors.
                <br></br><br></br>
                That way we try to be as fair as we can so anyone have the chance to get selected as a tester.
              </AccordionItem>
              <AccordionItem title="Do I Have To Install Anything?">
                Once you have been selected for a test, we will let you know via email, the next steps that you should take.                   </AccordionItem>
              <AccordionItem title="How Am I Going To Get Paid?">

                We currently support payouts only via Paypal.



              </AccordionItem>
              <AccordionItem title="How Much Am I Going To Get Paid?">
                Depending on the type of test that you will execute you can earn up to $5 to $15 per test.
              </AccordionItem>
              <AccordionItem title="Why do I have to do the qualification test?">
                It's the safest and quickest way for you as a tester to find out if you phone can support our device and understand the testing process.
                It's also a way for us to validate that your response the qualification playtest is up to our standards. We aim to have quality testers.
              </AccordionItem>
              <AccordionItem title="Is this a full time or part time job?">
                <b>NO!</b>
                <br></br>
                Expect to receive 1-2 paid tests per month if you demographics and gaming behavior matches the criteria our clients set.
              </AccordionItem>
              <AccordionItem title="I am developer! Where can I learn more?">
                If you a developer please visit: <a target="_blank" href="https://playtesting.games/"> playtesting.games</a>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>

    );
  }
}

FAQPageComponent.propTypes = propTypes;
FAQPageComponent.defaultProps = defaultProps;

export default FAQPageComponent;