import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/terms-of-service">Terms of Service</Link>
        </li>
        {/* <li>
          <a href="https://app.octappush.com/terms-of-service/">
            Terms of Service - Developers
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default FooterNav;
