import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class HowItWorks extends React.Component {

  render() {

    const {
      className,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'How it works',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
        style={{
            backgroundColor: `transparent`,
            backgroundImage: `linear-gradient(120deg, #1c8621 0%, #2096f3 100%)`,
            transition: `background 0.3s, border-radius 0.3s, opacity 0.3s`
        }}
      >
        
                {/* <video 
                  autoPlay 
                  loop 
                  muted 
                  className="HeaderVid"
                >
                  <source src="https://www.octappush.com/tester/demo.mp4" type="video/mp4"/>
                </video> */}
        {/* <video 
            className="elementor-background-video-hosted elementor-html5-video" 
            autoPlay="" 
            muted="" 
            playsInline="" 
            loop="" 
            src="https://www.octappush.com/tester/demo.mp4" 
            style={{
                width: `100%`,
                height: `500px`
            }}>
            </video> */}
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content invert-color" />
            <div className={tilesClasses}>


              <div className="tiles-item reveal-scale-up" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow"
                style={{    boxShadow: `rgba(6, 6, 6, 0.1) 0px 0px 19px 4px`,
                borderRadius: `15px`,
                paddingTop: `20px`}}>
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/howItWorks/one.svg')}
                        alt="Testimonial 02"
                        width={56}
                        height={56} />
                    </div>
                    
                   
                    <div className="testimonial-item-content"
                    style={{paddingTop: `30px`}}>
                        <span className="testimonial-item-name">
                            <b>Set up your playtest in minutes</b>
                        </span>
                        <p className="text-sm mb-0 reveal-from-right is-revealed">
                        Select your audience, make the survey and upload your design or game.
                        </p>
                    </div>

                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-scale-up" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow"
                style={{    boxShadow: `rgba(6, 6, 6, 0.1) 0px 0px 19px 4px`,
                borderRadius: `15px`,
                paddingTop: `20px`}}>
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/howItWorks/two.svg')}
                        alt="Testimonial 02"
                        width={56}
                        height={56} />
                    </div>
                    <div className="testimonial-item-content"
                    style={{paddingTop: `30px`}}>
                        <span className="testimonial-item-name">
                            <b>Your game is tested</b>
                        </span>
                        <p className="text-sm mb-0 reveal-from-right is-revealed">
                        We match you with the right gamers. They play your game and answer your questions.
                        </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-scale-up" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow"
                style={{    boxShadow: `rgba(6, 6, 6, 0.1) 0px 0px 19px 4px`,
                borderRadius: `15px`,
                paddingTop: `20px`}}>
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/howItWorks/three.svg')}
                        alt="Testimonial 02"
                        width={56}
                        height={56} />
                    </div>
                    <div className="testimonial-item-content"
                    style={{paddingTop: `30px`}}>
                        <span className="testimonial-item-name">
                            <b>Get the results</b>
                        </span>
                        <p className="text-sm mb-0 reveal-from-right is-revealed">
                        You get the videos from the playtest and each player's answers
                        </p>
                    </div>
                   
                  </div>
                </div>
              </div>  

            </div>
          </div>
        </div>
      </section>
    );
  }
}

HowItWorks.propTypes = propTypes;
HowItWorks.defaultProps = defaultProps;
    
export default HowItWorks;