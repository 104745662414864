import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import HeroFull from '../components/sections/HeroFull';
import PrivacyPolicy from '../components/sections/PrivacyPolicy';

import FAQ from '../components/sections/FAQ';
import ReactGA from 'react-ga';
import HowItWorks from '../components/sections/HowItWorks';


ReactGA.initialize('UA-71944613-7');

class Privacy extends React.Component {
  
  render() { 
    return (
      <React.Fragment>
        <PrivacyPolicy />
      </React.Fragment>
    );
  }
}

export default Privacy;