import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import TermsOfSericeDevs from "./TermsOfSericeDevs";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

class TermsOfService extends React.Component {
  render() {
    return (
      <section style={{ padding: `0px`, paddingTop: `70px` }}>
        <div className="container">
          <h1 class="h1-seo">Terms Of Service</h1>

          <div class="row">
            <p>
              <strong>
                <em>
                  <span data-contrast="none">Date: 11.11.2020 </span>
                </em>
              </strong>
              &nbsp;
            </p>
            <p>&nbsp;</p>
            <p><span data-contrast="auto">Please read these Terms of Service (&ldquo;Terms&rdquo;, &ldquo;Terms of Service&rdquo;) carefully before using octappush.com, playtesting.games or the Octappush mobile applications (collectively called as the &ldquo;Service&rdquo;) operated by Christos Sinopoulos SP ("Octappush", &ldquo;Us&rdquo;, &ldquo;We&rdquo;, or &ldquo;Our&rdquo;). Individuals or companies that assign Us to perform tests for them are Our clients ("Client" or "Clients").</span>&nbsp;</p>
<p><span data-contrast="auto">Signing up on Our Service as a tester ("Tester" or "Testers") binds you with these Terms as a whole and in particular with accessing your account and the extra rights and obligations as a Tester you might have.&nbsp;</span>&nbsp;</p>
<p><span data-contrast="auto">These Terms of Service also apply to all visitors, users and others who access or use the Service.&nbsp;</span>&nbsp;</p>
<p><span data-contrast="auto">By accessing or using the Service you agree to be bound by these Terms.&nbsp;</span>&nbsp;</p>
<p><span data-contrast="auto">If you disagree with any part of the terms, then you may not access the Service.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><span data-contrast="none">Use License</span></strong>&nbsp;</p>
<p><span data-contrast="auto">Permission is granted to temporarily use Our Service. This is the grant of a license, not a transfer of title, and under this license you may not:</span>&nbsp;</p>
<ul>
<li data-aria-posinset="1" data-aria-level="2"><span data-contrast="auto">modify or copy the materials;</span>&nbsp;</li>
<li data-aria-posinset="2" data-aria-level="2"><span data-contrast="auto">use the materials for any commercial purpose or for any public display;</span>&nbsp;</li>
<li data-aria-posinset="3" data-aria-level="2"><span data-contrast="auto">attempt to reverse engineer any software of Our Service</span>&nbsp;</li>
<li data-aria-posinset="4" data-aria-level="2"><span data-contrast="auto">remove any copyright or other proprietary notations from the materials; or</span>&nbsp;</li>
<li data-aria-posinset="5" data-aria-level="2"><span data-contrast="auto">transfer the materials to another person or &ldquo;mirror&rdquo; the materials on any other server.</span>&nbsp;</li>
</ul>
<p><span data-contrast="auto">This will let Octappush to terminate upon violations of any of these restrictions. Upon termination, your account will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</span>&nbsp;</p>
<p><strong><span data-contrast="none">Octappush's Rights and Obligations</span></strong>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service. You expressly agree that any such offer of a product or service does not constitute a legal offer capable of attracting legal consequences.</span>&nbsp;</p>
<p><span data-contrast="auto">We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">Our Service performs algorithms to creates a fair system for all Testers to be selected to participate in Our tests in correlation with the criteria of Testers Our Clients have decided. Testers that are evaluated by our Clients and/or Us as of higher quality have higher chances to be selected from Our algorithm.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">Octappush will send app notifications (or emails) to Testers to inform them of new available tests whenever it is appropriate but We are not obliged. We are not responsible if the Tester misses or views any of Our notifications (or emails) at a later time and in consequentially misses the opportunity to participate in a test.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">We have the right to not select a Tester for a test if we have proof that any of the information that the Tester provide Us during sign-up is not correct (for example Tester's selected location during sign-up doesn't match their real world location).</span>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><span data-contrast="none">Links, Third party services and Games</span></strong>&nbsp;</p>
<p><span data-contrast="auto">Our Service may contain links to third-party web sites, services and games that are not owned or controlled by Octappush. We have no control over any of these we are linked to so we cannot be responsible for safeguarding any information which you provide on any of these and these Terms does not govern them.</span>&nbsp;</p>
<p><span data-contrast="auto">Octappush has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites, services and games. You further acknowledge and agree that Octappush shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites, services or games. We strongly advise you to read the terms of service and privacy policies of any third-party web sites, services and games that we are linked.</span>&nbsp;</p>
<p><strong><span data-contrast="none">Intellectual Property</span></strong>&nbsp;</p>
<p><span data-contrast="auto">The Service and its original content, features and functionality are and will remain the exclusive property of Octappush and its licensors. Our trademarks may not be used in connection with any product or service without the prior written consent of Octappush.</span>&nbsp;</p>
<p><strong><span data-contrast="auto">Tester's Rights, Obligations and Rights of use</span></strong>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester is not permitted to change their information upon completion of the sign-up. The only way that this can be done is through email with our support department at support@octappush.com.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Testers assures that all personal details are accurate, complete and correct at any time. If they change, the Tester should change them immediately. The Tester is the only responsible for providing a valid PayPal address.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester is obliged to play/test the game assigned from Us personally.&nbsp;</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester assures that is giving their most sincere feedback and not being biased by any conditions (for example competitiveness) that affect their willingness to provide feedback that matches their personal opinions.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester is obliged to create only one account. The creation of more than one, gives Us the right to delete all of them and block the Tester from future registering again. If the Tester has any objection about the deletion or wants to correct any of their personal details We are willing, but not obliged, to resolve the matter by contacting Us through our support department at support@octappush.com.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">Both parties (the Tester and Octappush) agree that the Tester is an independent contractor and not an employee, agent or representative of Octappush and consequently the Tester has any rights.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester is obliged and responsible for the device and the internet connection. The Tester is obliged to play/use the games on their own personal devices (not commercial or professional).</span>&nbsp;</p>
<p><span data-contrast="auto">In case there are any kind of anomalies while the Tester performs the test (for example bad internet connection or the game crushes), they have the right to submit a request to perform the test again by contacting Our support department at support@octappush.com. We have the right not to accept the request.&nbsp;&nbsp;</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester has an obligation to follow the instructions before and after the test as displayed in our Service.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The data the Tester creates during the test belong to Octappush and/or the Client of that specific test if not explicitly stated otherwise.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester is obliged not to copy, edit, save or keep in any other possible way, the data or the content of the games that are provided through Our Service.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The results that the Tester provides to Octappush in any form (for example questionnaire) are property of Octappush and the Tester has no right upon them for use, view or publish. We have the right to use them in any form suitable for our business purposes complying with our&nbsp;</span><a href="http://www.octappush.com/about/privacy"><span data-contrast="auto">Privacy Policy</span></a><span data-contrast="auto">.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester has no right to use the game after the testing period unless explicitly stated by Octappush.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The completion of the test is defined as by the completion of the testing results and transfer to Octappush.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester has an obligation not to mention, share or publish any information about the game, the content or any bugs it may contain in private or public.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The communication between the Tester and Octappush is official only through email or through the extend that the Service provides.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester is obliged not to communicate any of Our Clients directly through any medium unless given written permission from Octappush.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><span data-contrast="auto">Terms of Payment</span></strong>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">Octappush and/or the Client of a test has the right to deny payment to a Tester if the results the Tester provided are not up to the standards or according to the instructions of the particular test. Octappush and/or the Client has the responsibility to provide a reason why the specific test result is not accepted. The Tester has the right to contact our support department at support@octappush.com to resolve the matter in a case to case scenario.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">Octappush agrees that when the test is successful will owe the tester the amount agreed as displayed in our Service.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester has the option to cash out the amount Octappush owes the Tester as displayed in Our Service once upon reaching the minimum threshold of $14 and using the cash out procedure that Our Service provides.&nbsp;</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">Requests to cash out by the Tester will need to be confirmed by Octappush at 30th of the month that were executed and Octappush will send the money to the Tester's PayPal account as provided within 5 days.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">United States Dollar is the currency of Our Service and the one that We pay the Testers. We are not responsible for any differentiation of the amount the Tester receives to their PayPal account from the "cash-out" amount the Tester requested from Octappush that have to do with currency exchanges.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester is the only responsible for any taxes that may arise as a result of earnings from Octappush.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><span data-contrast="auto">Termination and deletion of the Tester's account</span></strong>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">We have the right to terminate the access and delete the account of The Tester in case the latter doesn't not comply with the Terms and the Tester has no right to open new account. The resolve of disputes can be done through our support department at support@octappush.com</span>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><span data-contrast="none">Disclaimer</span></strong>&nbsp;</p>
<p><span data-contrast="auto">Your use of the Service is at your sole risk. The Service is provided on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</span>&nbsp;</p>
<p><span data-contrast="auto">Octappush, its subsidiaries, affiliates, and its licensors do not warrant that: The Service will function uninterrupted, secure or available at any particular time or location, any errors or defects will be corrected, the results of using the Service will meet your requirements.</span>&nbsp;</p>
<p><strong><span data-contrast="none">Accounts</span></strong>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with Our Service or a third-party service.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">You agree not to reveal your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of your account.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><span data-contrast="auto">Limitations of Liability</span></strong>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">In no event shall Octappush, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not We have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><span data-contrast="none">Indemnification</span></strong>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">The Tester has the obligation to indemnify Octappush and/or it's Clients for the infringement of these Terms.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><span data-contrast="auto">These expenses include but is not limited to loss of earnings by Octappush and/or its Clients and legal expenses of Octappush that may be required.</span>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><span data-contrast="none">Modifications</span></strong>&nbsp;</p>
<p><span data-contrast="auto">We reserve the right, at Our sole discretion, to modify or replace these Terms of Service at any time. When We do such updates, We revise the updated date at the top of this page. You acknowledge and agree that it is your responsibility to review these Terms periodically and become aware of modifications.</span>&nbsp;</p>
<p><span data-contrast="auto">The Tester has the right to object to the new terms. By continuing to access or use Our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you must stop using the Service.</span>&nbsp;</p>
<p><strong><span data-contrast="none">Termination</span></strong>&nbsp;</p>
<p><span data-contrast="auto">We may terminate or suspend the Tester's account immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if the Tester breaches the Terms.</span>&nbsp;</p>
<p><span data-contrast="auto">Upon termination, the Tester's right to use the Service will immediately cease. The Tester has the right to terminate their account by contacting Our support department at support@octappush.com</span>&nbsp;</p>
<p><span data-contrast="auto">All provisions of the Terms, which by their nature should survive termination, shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</span>&nbsp;</p>
<p><strong><span data-contrast="none">Exclusions</span></strong>&nbsp;</p>
<p><span data-contrast="auto">Without limiting the generality of the foregoing and notwithstanding any other provision of these terms, under no circumstances will Octappush ever be liable to you or any other person for any indirect, incidental, consequential, special, punitive or exemplary loss or damage arising from, connected with, or relating to your use of the Service, these Terms, the subject matter of these Terms, the termination of these Terms or otherwise, including but not limited to personal injury, loss of data, business, markets, savings, income, profits, use, production, reputation or goodwill, anticipated or otherwise, or economic loss, under any theory of liability regardless of any negligence or other fault or wrongdoing (including, without limitation, gross negligence and fundamental breach) by Octappush or any person for whom Octappush is responsible, and even if Octappush has been advised of the possibility of such loss or damage being incurred.</span>&nbsp;</p>
<p><strong><span data-contrast="none">Governing Law</span></strong>&nbsp;</p>
<p><span data-contrast="auto">These Terms shall be governed and construed in accordance with the laws of Greece without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between Us regarding our Service, and supersede and replace any prior agreements we might have between Us regarding the Service.</span>&nbsp;</p>
<p><strong><span data-contrast="none">Privacy</span></strong>&nbsp;</p>
<p><span data-contrast="auto">As a Service, we require users to provide Us with some information. We respect their privacy through our Privacy Policy that can be found </span><a href="http://www.octappush.com/about/privacy/"><span data-contrast="auto">here</span></a><span data-contrast="auto">.</span>&nbsp;</p>
<p><strong><span data-contrast="none">Contact Us</span></strong>&nbsp;</p>
<p><span data-contrast="auto">If you have any questions about these Terms, please contact Us at support@octappush.com</span>&nbsp;</p>
          </div>
        </div>
      </section>
    );
  }
}

TermsOfService.propTypes = propTypes;
TermsOfService.defaultProps = defaultProps;

export default TermsOfService;
