import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import Button from './../elements/Button';
import Modal from './../elements/Modal';
import Image from './../elements/Image';
import Input from './../elements/Input';
import axios from 'axios';
import ReactGA from 'react-ga';


// import { 
//   Label, 
//   Form,  
//   FormGroup,
//   InputGroupAddon,
//   InputGroupText,
//   InputGroup,Row,Col
// } from "reactstrap";
const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomDivider: false
}

class Header extends React.Component {

  state = {
    isActive: false,
    demoModalActive: false
  };


  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount() {
    this.props.active && this.openMenu();
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    ReactGA.initialize('UA-71944613-5');

  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + 'px';
    this.setState({ isActive: true });
  }

  closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    this.nav.current && (this.nav.current.style.maxHeight = null);
    this.setState({ isActive: false });
  }

  keyPress = (e) => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  }

  clickOutside = (e) => {
    if (!this.nav.current) return
    if (!this.state.isActive || this.nav.current.contains(e.target) || e.target === this.hamburger.current) return;
    this.closeMenu();
  }

  clickedGetStarted = (e) => {
    ReactGA.event({
      category: 'developer_button',
      action: 'developer_button',
      label: 'developer_button'
    });

    var url = "https://playtesting.games/?utm_source=octappush.com_devlink&utm_medium=octappush.com_devlink&utm_campaign=octappush.com_devlink&utm_id=octappush.com_devlink";
    // window.location.replace(url);
    var win = window.open(url, '_blank');
    win.focus();
    e.preventDefault();
  }



  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  handleSubmitDevForm = (event) => {

    // var state2 = this;
    // axios({
    //   method: 'post',
    //   url: 'https://23dbtikyf0.execute-api.eu-central-1.amazonaws.com/deployment/playtesting-games/dev-form',
    //   data: {
    //     first_name: event.target[0].value,
    //     last_name: event.target[1].value,
    //     company_email: event.target[2].value,
    //   }
    // })
    // .then(res => {
    //   console.log("response: " + res.data);
    //   // state2.setState({ showSuccessMessage: true })
    // })
    // .catch(error => {
    //   console.log("ERROR!! " + error)
    // })
    event.preventDefault();
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }


  render() {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-header', 'flex', 'justify-between', 'items-center',
      className,

    );

    return (
      <header
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-header-inner', 'flex', 'justify-between', 'items-center',
              bottomDivider && 'has-bottom-divider'
            )}>
            <div className={
              classNames(
                'site-header-inner', 'flex', 'justify-between', 'items-center',
                bottomDivider && 'has-bottom-divider'
              )}>
              <Logo /> <h1 className="logo_text">Octappush</h1>
            </div>
            {!hideNav &&
              <React.Fragment>

              </React.Fragment>}
            <div>
            
              <Button
                className="bg-blue-500 hover:bg-blue-700 text-sm text-black font-bold py-5 px-2 rounded"

                style={{ borderRadius: `10px`, fontSize: `14px`, padding: `0.5rem`, fontWeight: `bold` }}
                tag="a"
                onClick={this.clickedGetStarted}
                target="_blank"
                color="light"
                href="https://playtesting.games/?utm_source=octappush.com_devlink&utm_medium=octappush.com_devlink&utm_campaign=octappush.com_devlink&utm_id=octappush.com_devlink"
                wideMobile>
                🎮 👨‍🔬 Developing Games?
              </Button>
            </div>
          </div>
        </div>
      </header>


    )
  }
}
const formStyle = {
  maxWidth: '520px',
  margin: '0 auto'
}
Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;