import React from "react";
import { withRouter, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import LayoutAlternative from "./layouts/LayoutAlternative";
import LayoutSignin from "./layouts/LayoutSignin";

// Views
import Home from "./views/Home";
import Secondary from "./views/Secondary";
import Login from "./views/Login";
import Signup from "./views/Signup";
import Terms from "./views/Terms";
import Privacy from "./views/Privacy";
import TermsDevs from "./views/TermsDevs";
import BecomeTester from "./views/BecomeTester";
import DeleteAccount from "./views/DeleteAccount";

import TOS_Redirect from "./views/TOS_Redirect";
import Privacy_Redirect from "./views/Privacy_Redirect";
import FAQPage from "./views/FAQPage";

class App extends React.Component {
  componentDidMount() {
    document.body.classList.add("is-loaded");
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute
              exact
              path="/secondary"
              component={Secondary}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/faq"
              component={FAQPage}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/login"
              component={Login}
              layout={LayoutSignin}
            />
            <AppRoute
              exact
              path="/account-deletion"
              component={DeleteAccount}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/signup"
              component={Signup}
              layout={LayoutSignin}
            />
            <AppRoute
              exact
              path="/signup"
              component={Signup}
              layout={LayoutSignin}
            />
            <AppRoute
              exact
              path="/terms-of-service"
              component={Terms}
              layout={LayoutDefault}
            />

            <AppRoute
              exact
              path="/privacy-policy"
              component={Privacy}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/become-tester"
              component={BecomeTester}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/about/tos/"
              component={TOS_Redirect}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/about/privacy"
              component={Privacy_Redirect}
              layout={LayoutDefault}
            />
          </Switch>
        )}
      />
    );
  }
}

export default withRouter((props) => <App {...props} />);
