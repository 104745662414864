import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Lottie from "lottie-react";
import * as animationDataTyping from '../../assets/animations/typing_1.json'
import * as animationDataCamera from '../../assets/animations/camera_1.json'
import * as animationDataFilters from '../../assets/animations/user_filters_1.json'
import * as animationDataStages from '../../assets/animations/stages.json'
import CountUp from 'react-countup';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop'
    );

    const sectionHeader = {
      title: 'Bold startup toolkit',
      paragraph: 'Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.'
    };

    const typingOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationDataTyping.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const cameraOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationDataCamera.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const userFilterOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationDataFilters.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const stagesFilterOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationDataStages.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    

    

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
            <div className={splitClasses}>

            <div className="split-item reveal-scale-up"
                style={{
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `center`
                }}
              >
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                  Your ideal audience in a few clicks
                  </h3>
                  <p className="m-0">
                  Suitable testers for all game genres and from every country tier.  Combine different filters to be as specific as you want.
                  </p>
                  <div className="CountupDiv">
                    
                    <div className="CountupItemDiv">
                      <CountUp end={1300} suffix="+" duration={2}
                        className="numbersCountup"
                      />
                      <p className="textCountup">Testers</p>
                    </div>

                    <div className="CountupItemDiv">
                      <CountUp end={48} duration={2}
                        className="numbersCountup"
                      />
                      <p className="textCountup">Countries</p>
                    </div>

                    <div className="CountupItemDiv">
                      <CountUp end={10} duration={3}
                        className="numbersCountup"
                      />
                      <p className="textCountup">Filters</p>
                    </div>

                  </div>
                </div>
                <div className={
                  classNames(
                    'is-3d illustration-element-04',
                    imageFill && 'split-item-image-fill'
                  )}>
                  
                  <div >
                    <Lottie options={userFilterOptions}
                      style={{
                        width: `300px`,
                        height: `300px`
                      }}
                    />
                  </div>
                
                  </div>

             
              </div>

              <div className="split-item reveal-scale-up"
                style={{
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `center`
                }}
              >
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                    Video recording, taps and sound
                  </h3>
                  <p className="m-0">
                  See the video replay of the playtest that includes the player's touch input to understand their motivation and their voice describing their emotions. 
                  </p>
                 </div>

                <div className={
                  classNames(
                    'is-3d illustration-element-04',
                    imageFill && 'split-item-image-fill'
                  )}
                  style={{
                    width: `auto`
                  }}
                  >
                  <div>
                    <Lottie options={cameraOptions}
                      style={{
                        width: `130px`,
                        overflow: `hidden`,
                        margin: `0px 0px 0px auto`,
                        outline: `none`,
                        marginRight: `0px`,
                        marginBottom: `-30px`
                      }}
                    />
                    <Lottie options={typingOptions}
                      style={{
                        width: `250px`,
                        height: `250px`
                      }}
                    />
                  </div>
                </div>
              </div>

              

              <div className="split-item reveal-scale-up"
                style={{
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `center`
                }}
              >
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                    In every stage of development 
                  </h3>
                  <p className="m-0">
                    Concept, Art Style, Prototype, Teaser, Vertical Slice, Demo
                  </p>
                </div>
                <div className={
                classNames(
                  'is-3d illustration-element-04',
                  imageFill && 'split-item-image-fill'
                )}>
                  <div >
                    <Lottie options={stagesFilterOptions}
                      style={{
                        width: `300px`,
                        height: `300px`
                      }}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;