import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Modal from './../elements/Modal';
import Input from './../elements/Input';
import axios from 'axios';
import ReactGA from 'react-ga';
import Lottie from "lottie-react";
import * as animationDatadeadPool from '../../assets/animations/deadpool.json'
import ReCAPTCHA from "react-google-recaptcha";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}
const recaptchaRef = React.createRef();
function onChange(value) {
  console.log("Captcha value:", value);
}
const defaultProps = {
  ...SectionProps.defaults,
  split: false
}




class Cta extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      videoModalActive: false,
      demoModalActive: false,
      formname: "demo",
      formemail: "demo"
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleSubmitDevForm = this.handleSubmitDevForm.bind(this);
    this.clickedGetStarted = this.clickedGetStarted.bind(this);

    ReactGA.initialize('UA-71944613-5');

  }

  openModal = (e) => {
    ReactGA.event({
      category: 'get_started',
      action: 'opened',
      label: 'footer_banner'
    });
    e.preventDefault();
  }


  clickedGetStarted = (e) => {
    ReactGA.event({
      category: 'get_started',
      action: 'opened',
      label: 'header'
    });

    var url = "https://play.google.com/store/apps/details?id=octappush.com.feedback&utm_source=octappush.com&utm_medium=header";
    // window.location.replace(url);
    var win = window.open(url, '_blank');
    win.focus();
    e.preventDefault();
  }

  handleSubmitDevForm(event) {
    // const recaptchaValue = recaptchaRef.current.getValue();
    // this.props.handleSubmit(recaptchaValue);
    // if(recaptchaValue == ""){
    //   console.log("recaptchaValue is null");
    // } else {
    console.log("recaptchaValue is valid, continue process");
    ReactGA.event({
      category: 'get_started',
      action: 'submitted',
      label: 'header'
    });
    var state2 = this;
    var name = this.state.formname;
    var email = this.state.formemail;
    this.closeModal(event);
    axios({
      method: 'post',
      url: 'https://23dbtikyf0.execute-api.eu-central-1.amazonaws.com/deployment/playtesting-games/dev-form',
      data: {
        name: name,
        email: email,
      }
    })
      .then(res => {
        console.log("response: " + res.data);
        state2.setState({ showSuccessMessage: true })
      })
      .catch(error => {
        console.log("ERROR!! " + error)
      })
    // }
    event.preventDefault();
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  handleChangeEmail(event) { this.setState({ formemail: event.target.value }); }
  handleChangeName(event) { this.setState({ formemail: event.target.value }); }

  render() {
    const {
      className,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content-mobile',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );


    const deadPoolOptions = {
      loop: true,
      autoplay: true,
      animationData: animationDatadeadPool.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };


    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div
            className={innerClasses}
          >
            <div >

            </div>

            <div className="cta-slogan reveal-from-left">
              <h3 className="m-0">
                Get Paid Testing Mobile Games
              </h3>

              {/* <p>Get 2 free playtests for your game</p> */}

            </div>
            <div className="cta-action reveal-from-right">

              <Button
                tag="a"
                color="primary"
                onClick={this.clickedGetStarted}
                wideMobile
                href="https://play.google.com/store/apps/details?id=octappush.com.feedback&utm_source=octappush.com&utm_medium=header"
                className="getStartedCTA"

              >
                Get started
              </Button>
            </div>
          </div>
        </div>

      </section>

    );
  }
}

const formStyle = {
  maxWidth: '520px',
  margin: '0 auto'
}
Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;