import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import HeroFull from '../components/sections/HeroFull';
import TermsOfSericeDevs from '../components/sections/TermsOfSericeDevs';

import FAQ from '../components/sections/FAQ';
import ReactGA from 'react-ga';
import HowItWorks from '../components/sections/HowItWorks';


ReactGA.initialize('UA-71944613-7');

class TermsDevs extends React.Component {
  
  render() { 
    return (
      <React.Fragment>
        <TermsOfSericeDevs />
      </React.Fragment>
    );
  }
}

export default TermsDevs;