import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class RequestAccountDeletion extends React.Component {

  render() {

    const {
      className,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content-mobile',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Gamers helping developers for a better gaming world',
      paragraph: 'FEATURES & BENEFITS'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <h1>Request Account Deletion</h1>
            <p>If you wish for you data and your account to be deleted, please send an email at support@octappush.com with the subject "Account Deletion".</p>
            <p>We will process your request with 5 working days.</p>
            <p>The data that will be deleted will be:</p>
            <ol>
              <li>Your email address</li>
              <li>Your username</li>
              <li>Any Device Identifiers</li>
              <li>Any other personal information you have provided</li>
              <li>Your survey responses</li>
              <li>Any games you have added to your account</li>
            </ol>
            <p>Once your account is deleted, you will not be able to recover it.</p>

          </div>
        </div>
      </section>
    );
  }
}

RequestAccountDeletion.propTypes = propTypes;
RequestAccountDeletion.defaultProps = defaultProps;

export default RequestAccountDeletion;