import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <section style={{ padding: `0px`, paddingTop: `70px` }}>
        <div className="container">
          <h1 class="h1-seo">Privacy Policy</h1>

          <div class="row">
            <div
              class="col-12"
              style={{ padding: `0px`, paddingTop: `20px`, margin: `0px` }}
            >
              <p>&nbsp;</p>
              <p>
                <p>
                  <strong>
                    <em>
                      <span data-contrast="none">Date: 11.11.2020</span>
                    </em>
                  </strong>
                  &nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  <span data-contrast="auto">
                    Octappush wants to ensure that your privacy is protected and
                    this privacy policy relates how uses and protects any
                    information provided to Octappush when you use
                    octappush.com, app.octappush.com, playtesting.games or the
                    Octappush mobile application (collectively called as the
                    &ldquo;Service&rdquo;) operated by Christos Sinopoulos SP
                    ("Octappush", &ldquo;Us&rdquo;, &ldquo;We&rdquo;, or
                    &ldquo;Our&rdquo;). Any information you provide will only be
                    used in accordance with this privacy statement.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    This Privacy Policy governs the manner in which Octappush
                    collects, uses, maintains and discloses information
                    collected from viewers (each, a &ldquo;Viewer&rdquo;)
                  </span>
                  <span data-contrast="auto">,&nbsp;</span>
                  <span data-contrast="auto">
                    testers (each, "Tester") and clients (each, "Client") of the
                    Service and as a whole
                  </span>
                  <span data-contrast="auto">,</span>
                  <span data-contrast="auto">
                    &nbsp;users (each, "User"). This privacy policy applies to
                    the Service and all other products and services offered by
                    Octappush.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">
                      Personal identification information
                    </span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    We may collect personal identification information from
                    Users in a variety of ways, including, but not limited to,
                    when Users visit our Service, register on the Service,
                    connect with third-party social media services through Our
                    Service, play a game, fill out a form, respond to a survey,
                    subscribe to the newsletter and in connection with other
                    activities, services, features or resources we make
                    available on Our Service. For each User, we collect and
                    store the following information:
                  </span>
                  &nbsp;
                </p>
                <ul>
                  <li data-aria-posinset="1" data-aria-level="2">
                    <span data-contrast="auto">
                      Name (First name, Last name)
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="2" data-aria-level="2">
                    <span data-contrast="auto">Age/date of birth</span>&nbsp;
                  </li>
                  <li data-aria-posinset="3" data-aria-level="2">
                    <span data-contrast="auto">
                      Physical address (Post Address, ZIP/Postal Code, City,
                      State, Country)
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="4" data-aria-level="2">
                    <span data-contrast="auto">
                      Contact information (Email)
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="5" data-aria-level="2">
                    <span data-contrast="auto">Password</span>&nbsp;
                  </li>
                  <li data-aria-posinset="6" data-aria-level="2">
                    <span data-contrast="auto">
                      Payment information (Paypal Account or Bank Account)
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="7" data-aria-level="2">
                    <span data-contrast="auto">
                      Company information (Name, VAT number)
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="8" data-aria-level="2">
                    <span data-contrast="auto">
                      Location information of User's device
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="9" data-aria-level="2">
                    <span data-contrast="auto">
                      Specifications of the device of Testers
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="10" data-aria-level="2">
                    <span data-contrast="auto">
                      Preferences and habits of Testers (Favorite game, favorite
                      game category, games played, date/times/duration playing
                      games etc.)
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="11" data-aria-level="2">
                    <span data-contrast="auto">
                      Any information provided by third-party social media
                      services about the User&rsquo;s account on those services.
                      This happens when, and only when, the User chooses to
                      connect to such a service through Our own Service and that
                      information are also governed by this Privacy Policy.
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="12" data-aria-level="2">
                    <span data-contrast="auto">
                      Additional information related to customer surveys or
                      offers.
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="13" data-aria-level="2">
                    <span data-contrast="auto">
                      Any information that the User gives to Our Service through
                      interactions of the User with Our Service (such as images,
                      comments, video and/or audio recordings of Playtests,
                      surveys answered and other material)
                    </span>
                    &nbsp;
                  </li>
                </ul>
                <p>
                  <span data-contrast="none"> </span>&nbsp;
                </p>
                <p>
                  <span data-contrast="none"> </span>&nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">Security</span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    To stop any unauthorized access or disclosure of your
                    information, appropriate electronic, physical and managerial
                    procedures have been implemented to protect the information
                    we gather online and ensure its security. We will collect
                    personal identification information from Users only if they
                    voluntarily give that information to Us. Users can always
                    refuse to supply personally identification information,
                    except that it may prevent them from engaging in certain
                    Service related activities.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="none"> </span>&nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">
                      Non-personal identification information
                    </span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    We may collect non-personal identification information about
                    Users whenever they interact with our Service. Non-personal
                    identification information may include the browser name, the
                    type of computer and technical information about Users means
                    of connection to Our Service, such as the operating system
                    and the Internet service providers utilized and other
                    similar information.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">Web Browser Cookies</span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    Octappush may use &ldquo;cookies&rdquo; to enhance User
                    experience. User&rsquo;s web browser places cookies on their
                    hard drive for record-keeping purposes and sometimes to
                    track information about them. The User may choose to set
                    their web browser to refuse cookies, or to alert you when
                    cookies are being sent. If they do so, note that some parts
                    of Our Service may not function properly.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="none"> </span>&nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">
                      How We Use Collected Information
                    </span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    Octappush collects and uses User's personal information for
                    the following purposes:
                  </span>
                  &nbsp;
                </p>
                <ul>
                  <li data-aria-posinset="1" data-aria-level="2">
                    <span data-contrast="auto">
                      To improve customer service. Your information helps Us to
                      more effectively respond to your customer service requests
                      and support needs.
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="2" data-aria-level="2">
                    <span data-contrast="auto">
                      To personalize user experience. We may use information in
                      the aggregate to understand how Our Users as a group use
                      the services and resources provided on our Service.
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="3" data-aria-level="2">
                    <span data-contrast="auto">
                      To improve Our Service. We continually strive to improve
                      Our Service offerings based on the information and
                      feedback We receive from you.
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="4" data-aria-level="2">
                    <span data-contrast="auto">
                      To administer a content, promotion, survey or other
                      Service feature. To send Users information they agreed to
                      receive about topics we think will be of interest to them.
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="5" data-aria-level="2">
                    <span data-contrast="auto">
                      To share non-personal identification information with
                      third parties. We may share or sell non-personal
                      identification information with third parties for
                      marketing or other purposes.
                    </span>
                    &nbsp;
                  </li>
                  <li data-aria-posinset="6" data-aria-level="2">
                    <span data-contrast="auto">To send periodic emails</span>
                    &nbsp;
                  </li>
                </ul>
                <p>
                  <span data-contrast="auto">
                    The User may receive emails that may include company news,
                    updates, related product or service information, etc. If at
                    any time the User would like to unsubscribe from receiving
                    future emails, We include detailed unsubscribe instructions
                    at the bottom of each email
                  </span>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="none"> </span>&nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">
                      How We Protect Your Information
                    </span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    We adopt appropriate data collection, storage and processing
                    practices and security measures to protect against
                    unauthorized access, alteration, disclosure or destruction
                    of your personal information, username, password,
                    transaction information and data stored on our Service.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="none"> </span>&nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">
                      Sharing Your Personal Information
                    </span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    We do not sell, trade, or rent User's personal
                    identification information to others. We may share generic
                    aggregated demographic information not linked to any
                    personal identification information regarding Users with Our
                    business partners, trusted affiliates and advertisers for
                    the purposes outlined above. We may use third party service
                    providers to help Us operate Our business and the Service or
                    administer activities on Our behalf, such as sending out
                    newsletters or surveys. We may share your information with
                    these third parties for those limited purposes.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="none"> </span>&nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">Third Party</span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    Users may find advertising or other content on our Service
                    that link to the sites and services of Our partners,
                    suppliers, advertisers, sponsors, licensors and other third
                    parties. We do not control the content or links that appear
                    on these sites and We are not responsible for the practices
                    employed by websites linked to or from Our Service. In
                    addition, these sites or services, including their content
                    and links, may be constantly changing. These sites and
                    services may have their own privacy policies and customer
                    service policies. Browsing and interacting on any other
                    website, including websites which have a link to our
                    Service, is subject to that website&rsquo;s own terms and
                    policies.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">Data collection of Clients</span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    When a Tester plays a game of a Client, the Client has the
                    ability to collect data from the Tester. We are not
                    responsible for the collection of that data and the Tester
                    shall read the privacy policy of the Client.&nbsp;
                  </span>
                  &nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="auto">The Right of Users</span>
                  </strong>
                  &nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  <span data-contrast="auto">
                    Users have the right to receive a copy of their information
                    by contacting Our support department at
                    support@octappush.com
                  </span>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    Users have the right to request the deletion of their
                    profile if created. That automatically does not guarantee
                    the deletion of all the information related with that
                    account.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    Users have the right to request the deletion of all or part
                    of their information by contacting Our support department at
                    support@octappush.com. We have the right to refuse the
                    request in a case by case situation.
                  </span>
                  &nbsp;
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <span data-contrast="none">
                      Changes to This Privacy Policy
                    </span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    Octappush has the discretion to update this privacy policy
                    at any time. When we do, we revise the updated date at the
                    top of this page. You acknowledge and agree that it is your
                    responsibility to review this privacy policy periodically
                    and become aware of modifications.
                  </span>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="none"> </span>
                  <strong>
                    <span data-contrast="none">
                      Your Acceptance of These Terms
                    </span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    By using this Service, you signify your acceptance of this
                    policy and of 
                  </span>
                  <a href="http://www.octappush.com/terms-of-service">
                    <span data-contrast="auto">Terms of Service</span>
                  </a>
                  <span data-contrast="auto">
                    . If you do not agree to this policy, please do not use our
                    Service. Your continued use of the Service following the
                    posting of changes to this policy will be deemed your
                    acceptance of those changes.  
                  </span>
                  &nbsp;
                </p>
                <p>
                  <strong>
                    <span data-contrast="none">Contacting Us</span>
                  </strong>
                  &nbsp;
                </p>
                <p>
                  <span data-contrast="auto">
                    If you have any questions about this Privacy Policy, the
                    practices of this site, or your dealings with this site,
                    please contact us at support@octappush.com
                  </span>
                  &nbsp;
                </p>
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

PrivacyPolicy.propTypes = propTypes;
PrivacyPolicy.defaultProps = defaultProps;

export default PrivacyPolicy;
