import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import ReactGA from 'react-ga';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      videoModalActive: false,
      demoModalActive: false,
      formname: "demo", 
      formemail: "demo"
    };
    this.clickedGetStarted = this.clickedGetStarted.bind(this);

    ReactGA.initialize('UA-71944613-5');


  }

  clickedGetStarted = (e) => {
    ReactGA.event({
      category: 'get_started',
      action: 'opened',
      label: 'header'
    });
    
    var url = "https://play.google.com/store/apps/details?id=octappush.com.feedback&utm_source=octappush.com&utm_medium=header";
    // window.location.replace(url);
    var win = window.open(url, '_blank');
    win.focus();
    e.preventDefault();
  }
  render() {

    const {
      className,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section', 'heroOverlay',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
          <div className="hero-content">
          
              <div className="split-item">
                <div className="hero-content split-item-content">
                  <h1 className="mt-0 mb-16 reveal-from-left whiteText">
                  Get Paid Testing Mobile Games​
                  </h1>
                  <p className="mt-0 mb-24 reveal-from-left whiteText" data-reveal-delay="200">
                  We are building a feedback platform for game Developers. If you are a mobile gamer that wants to test awesome mobile games, join us!
                  </p>
                  <div className="reveal-from-left" data-reveal-delay="400">
                    <Button 
                      style={{    borderRadius: `30px`, padding: `12px 103px`}}
                      tag="a" 
                      onClick={this.clickedGetStarted} 
                      target="_blank" 
                      color="primary" 
                      href="https://play.google.com/store/apps/details?id=octappush.com.feedback&utm_source=octappush.com&utm_medium=header" 
                      wideMobile>
                      Get started
                    </Button>
                  </div>
                </div>
                {/* <div className="hero-figure is-3d split-item-image illustration-element-01 reveal-scale-up">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/hero-image.svg')}
                    alt="Hero"
                    width={528}
                    height={396} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;